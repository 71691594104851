import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { MultiSelect } from 'primereact/multiselect';
import './DataTableDemo.css';

import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import axios from 'axios';
import { InputNumber } from 'primereact/inputnumber';
import { addLocale } from 'primereact/api';
import { InputSwitch } from 'primereact/inputswitch';





const Promosyon = () => {
    let emptyPromo = {
        promocodeID: -1,
        code: null,
        customerID: 0,
        unitPrice: null,
        minPrice: null,
        updated_at: null,
        create_at: null,
        endDate: null,
        startDate: null,
        status: null,
        categoryList: null,
        validDiscountProduct: null,

    };



    const [productDialog, setProductDialog] = useState(false);
    const [promoCode, setPromoCode] = useState(emptyPromo);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [promoList, setPromoList] = useState(null);
    const [editCategory, setEditCategory] = useState(false);
    const [ilkTarih, setIlkTarih] = useState(null);
    const [yenile, setYenile] = useState(false);
    const [visibleSil, setVisibleSil] = useState(false);
    const [categoryList, setCategoryList] = useState(null);
    const [categories, setCategories] = useState([]);

    const toast = useRef(null);
    const dt = useRef(null);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}Promocode/GetCode`).then((response) => {
            setPromoList(response?.data?.data)
        })
    }, [yenile])
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}Category/GetCategoryList`).then((response) => {
            setCategoryList(response?.data?.data?.categories)

        })
    }, [yenile])
    useEffect(() => {
        categoryList?.map((category) => {
            let kategori = { ...categories, name: category.categoryName, code: category.categoryId }
            categories.push(kategori)

        })
    }, [categoryList])


    addLocale('en', {
        firstDayOfWeek: 1,
        dayNames: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
        // dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['P', 'P', 'S', 'Ç', 'P', 'C', 'C'],
        monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
        // monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Gün',
        // clear: 'Limpiar'
    });

    const openNew = () => {

        let startDate = new Date();
        startDate.setHours(startDate.getHours() + 3)
        let sonTarih = startDate.toISOString();
        setIlkTarih(sonTarih)
        setPromoCode(emptyPromo);
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let charactersLength = characters.length;

        let result = "";
        let s = 8;
        for (var i = 0; i < s; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        let prom = { ...promoCode }
        prom.code = result;
        setPromoCode(prom)



        setSubmitted(false);
        setProductDialog(true);
    }
   

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setPromoCode(emptyPromo)
        setEditCategory(false)
    }


    const saveCategory = () => {
        setSubmitted(true);
        let categories = promoCode?.categoryList?.toString()
        if (promoCode.promocodeID == -1 && promoCode.code !== null && promoCode.unitPrice !== null && promoCode.endDate !== null) {
            let addPromo = {
                code: promoCode.code,
                customerID: promoCode.customerID,
                unitPrice: promoCode.unitPrice,
                minPrice: promoCode.minPrice,
                updated_at: ilkTarih,
                create_at: ilkTarih,
                endDate: promoCode.endDate,
                startDate: ilkTarih,
                status: true,
                categoryList: categories,
                validDiscountProduct: promoCode.validDiscountProduct == null ? false : true

            }

            axios.post(`${process.env.REACT_APP_API_URL}Promocode/AddCode`, addPromo).then((response) => {
                if (response.status == 200) {
                    setYenile(!yenile)
                    toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Promosyon Kodu Eklendi', life: 2000 });
                    setProductDialog(false)
                    setPromoCode(emptyPromo)
                    setEditCategory(false)
                }
            })
        } else {

            let updateCode = {
                promocodeID: promoCode.promocodeID,
                code: promoCode.code,
                customerID: promoCode.customerID,
                unitPrice: promoCode.unitPrice,
                minPrice: promoCode.minPrice,
                updated_at: ilkTarih,
                create_at: ilkTarih,
                endDate: promoCode.endDate,
                startDate: ilkTarih,
                status: promoCode.status,
                categoryList: categories,
                validDiscountProduct: promoCode.validDiscountProduct


            }
 
            axios.put(`${process.env.REACT_APP_API_URL}Promocode/UpdateCode`, updateCode).then((response) => {
                if (response.status == 200) {
                    setYenile(!yenile)
                    toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Promosyon Kodu Güncellendi', life: 2000 });
                    setProductDialog(false)
                    setPromoCode(emptyPromo)
                    setEditCategory(false)


                }
            })

        }
    }

    const editProduct = (promo) => {
        let listCate = ({ ...promo })
        // let diziStr = listCate.categoryList.split(', ');
        let b = listCate.categoryList.split(',').map(function (item) {
            return parseInt(item, 10);
        });
        listCate.categoryList = b;


        setPromoCode({ ...listCate });
        let startDate = new Date();
        startDate.setHours(startDate.getHours() + 3)
        let sonTarih = startDate.toISOString();
        setIlkTarih(sonTarih)
        // setPromoCode({ ...promo });

        setProductDialog(true);
        setEditCategory(true)

    }

    const silPromo = (rowData) => {
        axios.delete(`${process.env.REACT_APP_API_URL}Promocode/DeleteCode?Id=${rowData.promocodeID}`).then((response) => {
            toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Promosyon Kodu Silindi', life: 2000 });
            setYenile(!yenile)
                })
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _promo = { ...promoCode };
        _promo[`${name}`] = val;

        setPromoCode(_promo);
    }
    const onCategoryChange = (e, name) => {
        // const val = (e.target && e.target.value) || '';
        let _promo = { ...promoCode };
        _promo[`${name}`] = e.value;

        setPromoCode(_promo);
    }



    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Yeni Kod Tanımla " icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                {/* <span className="p-column-title">Code</span> */}
                {rowData.promocodeID}
            </>
        );
    }

    const promoBodyTemplate = (rowData) => {
        return (
            <>
                {/* <span className="p-column-title">Name</span> */}
                {rowData.code}
            </>
        );
    }




    const bakiyeTemplate = (rowData) => {
        return (
            <>
                <div className='text-success'>
                    % {rowData.unitPrice}
                </div>

            </>
        );
    }
    const endDateTemplate = (rowData) => {
        return (
            <>
                {new Date(rowData.endDate).toLocaleString().split(' ')[0]}
            </>
        );
    }



    const statusBodyTemplate = (rowData) => {
        return (
            <>
                {/* <span className="p-column-title">Status</span> */}
                {rowData.status == true ? "Kullanımda" : "Kullanımda Değil"}
            </>
        )
    }
    const DisountValidBodyTemplate = (rowData) => {
        return (
            <>
                {/* <span className="p-column-title">Status</span> */}
                {rowData.validDiscountProduct == true ? "Uygulanabilir." : "Uygulamanaz"}
            </>
        )
    }
    const onStatusChange = (e) => {
        let durum = e.value;
        let promo = { ...promoCode };
        promo.status = durum;
        setPromoCode(promo)

    }
    const onValidDiscountChange = (e) => {
        let durum = e.value;
        let promo = { ...promoCode };
        promo.validDiscountProduct = durum;
        setPromoCode(promo)

    }

    const actionBodyTemplate = (rowData) => {
        return (

            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-success mr-4"
                    onClick={() => editProduct(rowData)}
                    tooltip="Güncelle" tooltipOptions={{ className: 'blue-tooltip', position: 'top' }}

                />

                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger mr-4"
                    onClick={() => silPromo(rowData)}
                    tooltip="Sil" tooltipOptions={{ className: 'blue-tooltip', position: 'top' }}

                />

            </React.Fragment>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">PROMOSYON KODU İŞLEMLERİ</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Promosyon Kodu Ara..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="İptal Et" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Kaydet" icon="pi pi-check" className="p-button-text" onClick={saveCategory} />
        </>
    );





    const onEndDate = (e) => {
        e.setHours(e.getHours() + 3)
        let gecerlilikTarihi = e.toISOString();
        let promo = { ...promoCode }
        promo.endDate = gecerlilikTarihi;
        setPromoCode(promo)
    }
    const onHide = () => {
        setVisibleSil(false);
    }
    return (
        <div className="datatable-rowexpansion-demo">
            <div className="card">
                <Toast ref={toast} position="top-center" />
                <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>

                {/* <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar> */}

                <DataTable
                    ref={dt}
                    value={promoList}
                    showGridlines
                    selection={selectedProducts}
                    onSelectionChange={(e) => setSelectedProducts(e.value)}
                    dataKey="promocodeID"
                    paginator
                    rows={10}
                    responsiveLayout="scroll"

                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Gösterilen promosyon kodu {first} ile {last} arasında, toplam promosyon kodu sayısı {totalRecords} "
                    globalFilter={globalFilter} emptyMessage="Promosyon kodu bulunamamıştır." header={header}>
                    <Column field="promocodeID" header="Kod Id" body={codeBodyTemplate} headerStyle={{ width: '5%', minWidth: '4rem' }}></Column>
                    <Column field="code" header="Promosyon Kodu" body={promoBodyTemplate} headerStyle={{ width: '12%', minWidth: '6rem' }}></Column>
                    <Column field="unitPrice" className='text-center text-success' header="İndirim Oranı" body={bakiyeTemplate} headerStyle={{ width: '7%', minWidth: '8rem' }}></Column>
                    <Column field="minPrice" className='text-center text-success' header="Minimum Tutar" body={(e)=> e.minPrice+" TL"} headerStyle={{ width: '7%', minWidth: '8rem' }}></Column>
                    <Column field="endDate" header="Son Kullanma Tarihi" body={endDateTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    <Column field="status" header="Kullanım Durumu" body={statusBodyTemplate} headerStyle={{ width: '9%', minWidth: '10rem' }}></Column>
                    <Column field="validDiscountProduct" header="İndirimli Ürüne Uygulanma" body={DisountValidBodyTemplate} headerStyle={{ width: '21%', minWidth: '10rem' }}></Column>
                    <Column header="İşlem Menüsü" bodyStyle={{ textAlign: 'center' }} body={actionBodyTemplate}></Column>
                </DataTable>
             

                <Dialog visible={productDialog} style={{ width: '950px' }} header="Promosyon Kodu İşlemleri" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                    <div className="p-fluid grid formgrid">
                        <div className="field col-12 md:col-6">
                            <span htmlFor="promosyonCode">Geçerli Kategori Seçiniz</span>
                            <MultiSelect
                                value={promoCode.categoryList}
                                options={categories}
                                optionLabel='name'
                                optionValue='code'
                                onChange={(e) => onCategoryChange(e, 'categoryList')}
                                placeholder="Kategori Seçiniz"
                                display="chip" />
                        </div>
                        <div className="field col-12 md:col-6">
                            <span htmlFor="promosyonCode">Promosyon Kodu</span>
                            <InputText id="promosyonCode" value={promoCode.code} onChange={(e) => onInputChange(e, 'code')} required className={classNames({ 'p-invalid': submitted && !promoCode.code })} />
                        </div>
                        <div className="field col-12 md:col-6">
                            <span htmlFor="bakiye">İndirim Oranı</span>
                            <InputNumber id="bakiye" mode="decimal" max={100} value={promoCode.unitPrice} onValueChange={(e) => onInputChange(e, 'unitPrice')} prefix="%" />

                        </div>
                        <div className="field col-12 md:col-6">

                            <span htmlFor="gecerliTarih">Geçerlilik Tarihi</span>
                            <Calendar
                                id="gecerliTarih"
                                value={editCategory ? new Date(promoCode.endDate) : promoCode.endDate}
                                onChange={(e) => onEndDate(e.target.value)}
                                dateFormat="dd/mm/yy"

                            />
                        </div>
                        <div className="field col-12 md:col-6">
                            <span htmlFor="minPrice">Minimum Sepet Tutarı</span>
                            <InputNumber id="minPrice" mode="decimal" suffix=" TL" minFractionDigits={2} maxFractionDigits={5} value={promoCode.minPrice} onValueChange={(e) => onInputChange(e, 'minPrice')} />

                        </div>
                        <div className="field col-12 md:col-6">
                            <span htmlFor="bakiye">Müşteri Numarası</span>
                            <InputNumber id="bakiye" mode="decimal" value={promoCode.customerID} onValueChange={(e) => onInputChange(e, 'customerID')} />

                        </div>



                        <div className="field col-12 md:col-6">
                            <span htmlFor="indirim-tutar">İndirimli Üründe Geçerlilik Durumu ?</span>
                            <br />
                            <InputSwitch className='mt-3'
                                checked={promoCode?.validDiscountProduct}
                                onChange={(e) => onValidDiscountChange(e)}
                            />

                        </div>


                        {
                            editCategory ? (
                                <>
                                    <div className="field col-12 md:col-6">
                                        <span htmlFor="indirim-tutar">Kullanım Durumu Seçiniz</span>
                                        <br />
                                        <InputSwitch
                                            checked={promoCode?.status}
                                            onChange={(e) => onStatusChange(e)}
                                        />

                                    </div>

                                </>
                            ) : (
                                <>
                                </>
                            )
                        }

                    </div>


                </Dialog>
                <ConfirmDialog visible={visibleSil} onHide={() => setVisibleSil(false)}
                    message="Promosyon Kodunu Silmek İstediğinize Emin Misiniz ?"
                    header="Dikkat ! " icon="pi pi-exclamation-triangle" rejectLabel="Hayır" acceptLabel="Evet" accept={silPromo} reject={onHide} />


            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Promosyon, comparisonFn);