import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { Route, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppConfig } from './AppConfig';

import Dashboard from './components/Dashboard';


import Category from './pages/Category';
import OrderPages from './pages/OrderPages';
import Products from './pages/Products';
import Login from './pages/Login';
import Promosyon from './pages/Promosyon';
import Customers from './pages/Customers';
import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import './Custom.scss'
import AlarmListesi from './pages/AlarmListesi';
import KurYonetimi from './pages/KurYonetimi';
import WebSlider from './pages/WebSlider';
import OutletProduct from './pages/OutletProduct';
import SepetTakip from './pages/SepetTakip';
import VitrinYonetimi from './pages/VitrinYonetimi';
import Blog from './pages/Blog';
import Property from './pages/Property';
import Marka from './pages/Marka';
import PazaryeriFiyatHesaplama from './pages/PazaryeriFiyatHesaplama';

const App = () => {

    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [login, setLogin] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();

    let menuClick = false;
    let mobileTopbarMenuClick = false;
    // window.location.reload()
    // console.log("window.location.reload()",window.location.reload());
    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        let ka = sessionStorage.getItem("ka");
        let pr = sessionStorage.getItem("pr");

        if (ka === "eswood" && pr === "123**") {
            setLogin(true)
        }
    }, [])

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const menu = [
        {
            label: 'Giriş',
            items: [{
                label: 'Anasayfa', icon: 'pi pi-fw pi-home', to: '/'
            }]
        },
        {
            label: 'ÜRÜN YÖNETİMİ', icon: 'pi pi-fw pi-clone',
            items: [
                { label: 'Sipariş İşlemleri', icon: 'pi pi-fw pi-shopping-bag', to: '/order' },
                { label: 'Ürün İşlemleri', icon: 'pi pi-fw pi-shopping-bag', to: '/products' },
                

            ]
        },
        {
            label: 'Diger Sayfalar',
            items: [
                { label: 'Kategori İşlemleri', icon: 'pi pi-fw pi-user-edit', to: '/category' },
                { label: 'Özellik İşlemleri', icon: 'pi pi-fw pi-tags', to: '/property' },
                { label: 'Marka İşlemleri', icon: 'pi pi-fw pi-ticket', to: '/marka' },
                { label: 'Promosyon Kodu İşlemleri', icon: 'pi pi-fw pi-credit-card', to: '/promosyon' },
                { label: 'Vitrin Yönetimi', icon: 'pi pi-fw pi-th-large', to: '/vitrinYonetimi' },
                { label: 'Müşteri Bilgileri', icon: 'pi pi-fw pi-user', to: '/customers' },
                { label: 'Stok Bilgi ve Alarm Listesi', icon: 'pi pi-fw pi-bell', to: '/alarm' },
                { label: 'Kur Yönetim İşlemleri', icon: 'pi pi-fw pi-dollar', to: '/kurYonet' },
                { label: 'Web Slider İşlemleri', icon: 'pi pi-fw pi-slack', to: '/webslider' },
                { label: 'Outlet Ürün Tanımla', icon: 'pi pi-fw pi-percentage', to: '/OutletProduct' },
                { label: 'Sepete Eklenen Ürünler', icon: 'pi pi-fw pi-shopping-cart', to: '/SepetTakip' },
                // { label: 'Pazaryeri Fiyat Hesapla', icon: 'pi pi-fw pi-chart-line', to: '/pazaryerifiyathesaplama' },
                { label: 'Blog Yönetimi', icon: 'pi pi-fw pi-align-center', to: '/blogYonetimi' },
            ]
        },
    ];

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
            {
                login ? (
                    <>
                        <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} setLogin={setLogin}
                            mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />
                        <div className="layout-sidebar" onClick={onSidebarClick}>
                            <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                        </div>
                        <div className="layout-main-container">
                            <div className="layout-main">
                                <Route path="/" exact render={() => <Dashboard colorMode={layoutColorMode} location={location} />} />
                                <Route path="/order" component={OrderPages} />
                                <Route path="/category" component={Category} />
                                <Route path="/property" component={Property} />
                                <Route path="/marka" component={Marka} />
                                <Route path="/products" component={Products} />
                                <Route path="/promosyon" component={Promosyon} />
                                <Route path="/customers" component={Customers} />
                                <Route path="/alarm" component={AlarmListesi} />
                                <Route path="/kurYonet" component={KurYonetimi} />
                                <Route path="/webslider" component={WebSlider} />
                                <Route path="/OutletProduct" component={OutletProduct} />
                                <Route path="/SepetTakip" component={SepetTakip} />
                                <Route path="/VitrinYonetimi" component={VitrinYonetimi} />
                                <Route path="/blogYonetimi" component={Blog} />
                                <Route path="/pazaryerifiyathesaplama" component={PazaryeriFiyatHesaplama} />
                            </div>
                            <AppFooter layoutColorMode={layoutColorMode} />
                        </div>

                    </>
                ) : (
                    <>
                        <Login setLogin={setLogin} />
                    </>
                )
            }

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>

        </div>
    );

}

export default App;
