import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import './DataTableDemo.css';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';
import { Tag } from 'primereact/tag';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';



const PazaryeriFiyatHesaplama = () => {
    let emptyPromo = {
        fiyatID: 0,
        productID: 0,
        satisFiyati: 0,
        pazaryeriID: 0,
        ekMaliyetFiyati: 0,
        kargoFiyati: 0,
        desiId: 0,
        toplamMaliyetFiyati: 0,
        karyuzdesi: 0
    };

    let emptyDesiTablosu = {
        desiNo: 0,
        fiyat: 0.0,
        fiyat2: 0.0,
        ad: "",
        id: 0,
        pazaryeriID: 0,
        sartFiyat: 0,
        shipperId: 0,
        sartFiyat100: 0,
        fiyat100: 0

    };



    const [selectedProducts, setSelectedProducts] = useState(null);


    const toast = useRef(null);
    const dt = useRef(null);
    const [submitted, setSubmitted] = useState(false);
    const [pazaryeri, setPazaryeri] = useState([]);
    const [yenile, setYenile] = useState(false);
    const [secilenPzr, setSecilenPzr] = useState(1);
    const [secilenPzrOran, setSecilenPzrOran] = useState(0);
    const [urunler, setUrunler] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [urun, setUrun] = useState(emptyPromo);
    const [urunList, setUrunList] = useState(null);
    const [urunDialog, setUrunDialog] = useState(false);
    const [desiDialog, setDesiDialog] = useState(false);
    const [desiList, setDesiList] = useState(null);
    const [kargoList, setKargoList] = useState(false);
    const [desiTablosu, setDesiTablosu] = useState(emptyDesiTablosu);
    const [rowClick, setRowClick] = useState(true);
    const [dataClick, setDataClick] = useState(true);



    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}Pazaryeri/GetUrunler?PazaryeriID=` + secilenPzr).then((response) => {
            setUrunler(response?.data?.data)
            setDataClick(true);
        })
    }, [yenile])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}Pazaryeri/GetPazaryerleri`).then((response) => {
            setPazaryeri(response?.data?.data)
        })
    }, [yenile])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}ProductList/ProductList`).then((response) => {
            setUrunList(response?.data?.data);
        })
    }, [])
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}Pazaryeri/GetKargoListesi`).then((response) => {
            setKargoList(response?.data?.data);
        })
    }, [])

    const onPazaryeriChange = (e, name) => {
        setDataClick(false);
        setSecilenPzr(e.value);
        var data = pazaryeri.filter(function (veri) {
            return veri.pazarYeriID === e.value;
        });
        setSecilenPzrOran(data[0].komisyonOrani * 100);
        setYenile(!yenile)
       
    }
    const gonderim = [
        { name: 'Evet', code: 1 },
        { name: 'Hayır', code: 0 }
    ];

    const onUrunChange = (e, input) => {
        if (input == "satisFiyati") {
            let durum = e.target.value;
            let prdct = { ...urun };
            prdct.satisFiyati = durum;
            setUrun(prdct)
        }
        if (input == "ekMaliyetFiyati") {
            let durum = e.target.value;
            let prdct = { ...urun };
            prdct.ekMaliyetFiyati = durum;
            setUrun(prdct)
        }
        if (input == "karmarji") {
            let durum = e.target.value;
            let prdct = { ...urun };
            prdct.karMarji = durum;
            setUrun(prdct)
        }

        if (input == "desiNo") {
            let prdct = { ...desiTablosu };
            let durum = e.target.value;
            prdct.desiNo = durum;
            setDesiTablosu(prdct)
        }
        if (input == "fiyat") {
            let prdct = { ...desiTablosu };
            let durum = e.target.value;
            prdct.fiyat = durum;
            setDesiTablosu(prdct)
        }
        if (input == "fiyat2") {
            let prdct = { ...desiTablosu };
            let durum = e.target.value;
            prdct.fiyat2 = durum;
            setDesiTablosu(prdct)
        }
        if (input == "sartFiyat") {
            let prdct = { ...desiTablosu };
            let durum = e.target.value;
            prdct.sartFiyat = durum;
            setDesiTablosu(prdct)
        }
        if (input == "fiyat100") {
            let prdct = { ...desiTablosu };
            let durum = e.target.value;
            prdct.fiyat100 = durum;
            setDesiTablosu(prdct)
        }
        if (input == "sartFiyat100") {
            let prdct = { ...desiTablosu };
            let durum = e.target.value;
            prdct.sartFiyat100 = durum;
            setDesiTablosu(prdct)
        }
        if (input == "secilenPzrOran") {
            setSecilenPzrOran(e.target.value);
        }
    }

    const onSecimPazarChange = (e) => {
        let prdct = { ...urun };
        prdct.pazaryeriID = e.value;
        prdct.desiId = 0;
        setUrun(prdct)
        var url = `${process.env.REACT_APP_API_URL}Pazaryeri/GetDesiListesi?PazaryeriID=` + e.value + "&sort=1"
        axios.get(url).then((response) => {
            setDesiList(response?.data?.data);
        })
    }
    const onSecimGonderimChange = (e) => {
        let prdct = { ...urun };
        prdct.satisFiyati = e.value;
        prdct.gonderimDrm = e.value;
        setUrun(prdct)

    }

    const onSecimKargoChange = (e) => {
        let kargo = { ...desiTablosu };
        kargo.shipperId = e.value;
        setDesiTablosu(kargo);
    }
    const onDesiChange = (e) => {
        let prdct = { ...urun };
        prdct.desiId = e.value;
        setUrun(prdct)
    }
    const onProductChange = (e) => {
        let prdct = { ...urun };
        prdct.productID = e.value;
        setUrun(prdct)
    }
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="grid y" >
                    <div className="  mr-3 mt-0">
                        <Dropdown
                            value={secilenPzr}
                            options={pazaryeri}
                            onChange={(e) => onPazaryeriChange(e, "pazarYeriID")}
                            optionLabel="pazarYeriAdi"
                            optionValue="pazarYeriID"
                            placeholder="Pazaryeri Seçin."
                        />
                    </div>
                    <div className="field  mr-3 mt-0">
                        <InputNumber value={secilenPzrOran} onValueChange={(e) => setSecilenPzrOran(e.target.value)} prefix="%" />

                    </div>

                    <div className="field  mt-0">
                        <Button icon={false} label='Desi Listele' className="p-button p-button-secondary ml-2" onClick={openDesi} />
                    </div>
                    <div className="field ml-2 mt-0">
                        <Button icon={false} label='ÜRÜN EKLE' className="p-button p-button-success ml-2" onClick={openNew} />
                    </div>
                    <div className="field  mt-0">
                        <Button icon={false} label='Komisyon Oranı Güncelle' className="p-button p-button-danger mr-2 ml-2" onClick={saveKomisyon} />
                    </div>
                </div>

            </React.Fragment>
        )
    }
    const openNew = () => {
        let prdct = { ...emptyPromo };
        setUrun(prdct)
        setSubmitted(false);
        setUrunDialog(true);
    }
    const openDesi = () => {
        if (secilenPzr > 0) {
            setDesiTablosu(emptyDesiTablosu)
            var url = `${process.env.REACT_APP_API_URL}Pazaryeri/GetDesiListesi?PazaryeriID=` + secilenPzr + "&sort=1"
            axios.get(url).then((response) => {
                setDesiList(response?.data?.data);
            })
            setDesiDialog(true);
        } else {
            toast.current.show({ severity: 'error', summary: 'Pazar Yeri Seçin', detail: 'Listelenecek Desi  İçin Pazar Yeri Seçin', life: 2000 });
        }



    }



    const hideDialog = () => {
        setSubmitted(false);
        setUrunDialog(false);
        let prdct = { ...emptyPromo };
        setUrun(prdct)
    }

    const hidedesiDialog = () => {

        setDesiDialog(false);
    }

    const saveKomisyon = () => {

        if (secilenPzr == 0) {
            toast.current.show({ severity: 'error', summary: 'Pazaryeri Seçimi Yapın', detail: 'Lütfen Seçim Yapınız.', life: 2000 });
            return;
        }
        if (secilenPzrOran == 0) {
            toast.current.show({ severity: 'error', summary: 'Komisyon Oranı Girin', detail: 'Lütfen Seçim Yapınız.', life: 2000 });
            return;
        }

        if (secilenPzrOran != 0 && secilenPzr != 0) {
            let komisyon = {
                pazarYeriID: secilenPzr,
                komisyonOrani: secilenPzrOran


            }
            axios.post(`${process.env.REACT_APP_API_URL}Pazaryeri/SetKomisyonOrani`, komisyon).then((response) => {
                if (response.status == 200 && response?.data?.status == 200) {
                    setYenile(!yenile)
                    toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Komisyon Oranı Güncellendi', life: 2000 });
                }
                if (response.status == 200 && response?.data?.status == 404) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Hata :' + response?.data?.messages, life: 2000 });

                }
            })

        } else {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Hata : Oran Güncellenmedi', life: 2000 });
        }

    }



    const saveCategory = () => {
        setSubmitted(true);
        if (urun.pazaryeriID == 0) {
            toast.current.show({ severity: 'error', summary: 'Pazaryeri Seçimi Yapın', detail: 'Lütfen Seçim Yapınız.', life: 2000 });
            return;
        }
        if (urun.productID == 0) {
            toast.current.show({ severity: 'error', summary: 'Ürün  Seçimi Yapın', detail: 'Lütfen Seçim Yapınız.', life: 2000 });
            return;
        }
        if (urun.desiId <= 0) {
            toast.current.show({ severity: 'error', summary: 'Desi Miktarı Boş Olamaz.', detail: 'Lütfen Seçim Yapınız.', life: 2000 });
            return;
        }
        if (urun.fiyatID == 0 && urun.pazaryeriID != 0 && urun.productID != 0 && urun.desiId != 0) {
            let addUrun = {
                fiyatID: 0,
                productID: urun.productID,
                satisFiyati: urun.satisFiyati,
                pazaryeriID: urun.pazaryeriID,
                ekMaliyetFiyati: urun.ekMaliyetFiyati,
                kargoFiyati: urun.kargoFiyati,
                desiId: urun.desiId,
                toplamMaliyetFiyati: urun.toplamMaliyetFiyati

            }
            axios.post(`${process.env.REACT_APP_API_URL}Pazaryeri/SetPazaryeri`, addUrun).then((response) => {
                console.log(response);
                if (response.status == 200 && response?.data?.status == 200) {
                    setYenile(!yenile)
                    toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Ürün Eklendi', life: 2000 });
                    setUrunDialog(false);

                }
                if (response.status == 200 && response?.data?.status == 404) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Hata :' + response?.data?.messages, life: 2000 });

                }
            })

        } else {

            let updateUrun = {
                fiyatID: urun.fiyatID,
                productID: urun.productID,
                satisFiyati: urun.satisFiyati,
                pazaryeriID: urun.pazaryeriID,
                ekMaliyetFiyati: urun.ekMaliyetFiyati,
                kargoFiyati: urun.kargoFiyati,
                desiId: urun.desiId,
                toplamMaliyetFiyati: urun.toplamMaliyetFiyati

            }
            axios.post(`${process.env.REACT_APP_API_URL}Pazaryeri/UpdatePazaryeri`, updateUrun).then((response) => {
                if (response.status == 200 && response?.data.status == 200) {
                    setYenile(!yenile);
                    toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Güncelleme İşlemi Başarılı'+response?.data.messages, life: 2000 });
                    setUrunDialog(false);

                }
            })
            setUrunDialog(false);

        }
    }
    const saveUpdateDesi = () => {

        if (desiTablosu.shipperId == 0) {
            toast.current.show({ severity: 'error', summary: 'Kargo Firması Seçin', detail: 'Lütfen Seçim Yapınız.', life: 2000 });
            return;
        }
        if (desiTablosu.desiNo == 0) {
            toast.current.show({ severity: 'error', summary: 'Desi Numarsı Girin', detail: 'Lütfen Seçim Yapınız.', life: 2000 });
            return;
        }
        if (desiTablosu.fiyat == 0) {
            toast.current.show({ severity: 'error', summary: 'Kargo Desi Fiyatını Tanımlayın.', detail: 'Lütfen Seçim Yapınız.', life: 2000 });
            return;
        }
        if (desiTablosu.sartFiyat != 0) {
            if (desiTablosu.fiyat2 == 0) {
                toast.current.show({ severity: 'error', summary: 'İndirimli Fiyat Tanımlayın Yada İndirim Şartını Sıfır (0) Olarak Tanımlayın.', detail: 'Lütfen Seçim Yapınız.', life: 2000 });
                return;
            }

        }

        let desi = { ...desiTablosu };
        desi.pazaryeriID = secilenPzr;
        setDesiTablosu(desi)

        if (desiTablosu.id == 0 && desiTablosu.shipperId != 0 && desiTablosu.desiNo != 0) {
            axios.post(`${process.env.REACT_APP_API_URL}Pazaryeri/SetDesi`, desiTablosu).then((response) => {
                if (response.status == 200 && response?.data?.status == 200) {
                    var url = `${process.env.REACT_APP_API_URL}Pazaryeri/GetDesiListesi?PazaryeriID=` + secilenPzr + "&sort=1"
                    axios.get(url).then((response) => {
                        setDesiList(response?.data?.data);
                        setYenile(!yenile)

                    })
                    toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Desi Eklendi', life: 2000 });
                    setUrunDialog(false);
                }
                if (response.status == 200 && response?.data?.status == 404) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Hata :' + response?.data?.messages, life: 2000 });

                }
            })

        } else {
            axios.post(`${process.env.REACT_APP_API_URL}Pazaryeri/SetDesi`, desiTablosu).then((response) => {
                if (response.status == 200 && response?.data?.status == 200) {
                    var url = `${process.env.REACT_APP_API_URL}Pazaryeri/GetDesiListesi?PazaryeriID=` + secilenPzr + "&sort=1"
                    axios.get(url).then((response) => {
                        setDesiList(response?.data?.data);
                        setYenile(!yenile)
                    })
                    toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Güncelleme İşlemi Başarılı', life: 2000 });
                    setUrunDialog(false);
                }
                if (response.status == 200 && response?.data?.status == 404) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Hata :' + response?.data?.messages, life: 2000 });

                }
            })

        }
    }

    const updateKarMarji = () => {
        let updateUrun = {
            fiyatID: urun.fiyatID,
            productID: urun.productID,
            satisFiyati: urun.satisFiyati,
            pazaryeriID: urun.pazaryeriID,
            ekMaliyetFiyati: urun.ekMaliyetFiyati,
            kargoFiyati: urun.kargoFiyati,
            desiId: urun.desiId,
            toplamMaliyetFiyati: urun.toplamMaliyetFiyati,
            karMarji: parseFloat(urun.karMarji.replace(",", "."))

        }
        axios.post(`${process.env.REACT_APP_API_URL}Pazaryeri/UpdateKarMarj`, updateUrun).then((response) => {
            if (response.status == 200 && response?.data.status == 200) {
                setYenile(!yenile)
                toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Güncelleme İşlemi Başarılı', life: 2000 });
                setUrunDialog(false);

            }
        })

    }

    const editProduct = (selecturun) => {
        let prdct = { ...selecturun };
        setUrun(prdct)
        setUrunDialog(true);
        axios.get(`${process.env.REACT_APP_API_URL}Pazaryeri/GetDesiListesi?PazaryeriID=` + prdct.pazaryeriID).then((response) => {
            setDesiList(response?.data?.data);
        })
    }

    const deleteData = (selecturun) => {
        let prdct = { ...selecturun };
        let deletedata = {
            productID: prdct.productID,
            pazaryeriID: prdct.pazaryeriID
        };
        axios.post(`${process.env.REACT_APP_API_URL}Pazaryeri/DeleteUrun`, deletedata).then((response) => {
            if (response.status == 200 && response?.data.status == 200) {
                setYenile(!yenile)
                toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Ürün Silindi.', life: 2000 });
            }
        })
    }
    const codeBodyTemplate = (rowData) => {
        return (
            <>{rowData.urunAdi}
            </>
        );
    }

    const promoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.stokKodu}
            </>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (

            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    size="small"
                    className="p-button-success p-2 mr-2"
                    onClick={() => editProduct(rowData)}
                    tooltip="Güncelle" tooltipOptions={{ className: 'blue-tooltip', position: 'top' }}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-danger p-2"
                    onClick={() => deleteData(rowData)}
                    tooltip="Sil" tooltipOptions={{ className: 'blue-tooltip', position: 'top' }}
                />
            </React.Fragment>
        );
    }
    const selectedDesi = (data) => {
        let desi = { ...data.value };
        Object.entries(desi).length === 0 ?
            setDesiTablosu(emptyDesiTablosu) :
            setDesiTablosu(desi);

    }
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Pazar Yerleri Satış Fiyatı Hesaplama</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ürün  Ara..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="İptal Et" icon="pi pi-times" className="p-button p-button-danger" onClick={hideDialog} />
            {/* <Button label="Kar Marjı Hesapla" icon="pi pi-percentage" className="p-button p-button-info" onClick={updateKarMarji} /> */}
            <Button label="Kaydet" icon="pi pi-check" className="p-button p-button-success" onClick={saveCategory} />
        </>
    );
    const karmarji = (rowData) => {
        return (

            <><Tag style={{ fontSize: '13px', background: 'linear-gradient(-225deg, #FFD700 0%, #FF8C00 48%, #FF4500 100%)', textAlign: 'center' }} value={"% " + rowData.karMarji}></Tag> </>
        );
    }
    const satistutari = (rowData) => {
        return (

            <><Tag style={{ fontSize: '12px', background: 'linear-gradient(-225deg, #FFD700 0%, #FF8C00 48%, #FF4500 100%)' }} className='text-center' value={rowData.satisFiyati + " TL"}></Tag> </>
        );
    }
    const kartutari = (rowData) => {
        return (
            <><Tag style={{ fontSize: '13px', textAlign: 'center' }} severity={rowData.karMarji.includes("-") ? "danger" : "success"} value={rowData.karTutari + " TL"}></Tag></>
        );
    }
    const pazarisimleri = (option) => {
        return <Tag style={{ fontSize: '13px', background: 'linear-gradient(-225deg,#AC32E4 0%,#7918F2 48%,#4801FF 100%)', textAlign: 'center' }} value={option.pazaryeriAdi} severity='success' />;
    };
    const gonderimdurumu = (option) => {
        return <Checkbox  disabled="true" checked={option.gonderimDrm == 0 ? false : true}></Checkbox>
    };


    return (
        <div className="datatable-rowexpansion-demo">
            <div className="card">
                <Toast ref={toast} position="top-center" />
                <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>
                <DataTable
                    ref={dt}
                    value={urunler}
                    loading={!dataClick}
                    showGridlines
                    selection={selectedProducts}
                    onSelectionChange={(e) => setSelectedProducts(e.value)}
                    dataKey="fiyatID"
                    paginator
                    rows={20}
                    className="datatable-responsive"
                    responsiveLayout="scroll"
                    rowsPerPageOptions={[20, 30, 50, 100, 400]}
                    size='small'
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Gösterilen Ürünler {first} ile {last} arasında, toplam ürün sayısı {totalRecords} "
                    globalFilter={globalFilter} emptyMessage="Ürün bulunamamıştır." header={header}>
                    <Column field="pazaryeriAdi" sortable className='text-center text-success' header="Paz.Yer.Adı" headerStyle={{ width: '5%', minWidth: '4rem' }} body={pazarisimleri} ></Column>
                    <Column field="komisyonOrani" className='text-center text-success' header="Kom.Orn." body={(e) => e.komisyonOrani + "%"} headerStyle={{ width: '2%', minWidth: '2rem' }}></Column>
                    <Column field="desiNo" className='text-center text-success' header="Desi Num." body={(e) => e.desiNo} headerStyle={{ width: '1%', minWidth: '2rem' }} ></Column>
                    {/* <Column field="kargoAdi" className='text-center text-success' header="Kargo Ad" body={(e) => e.kargoAdi} headerStyle={{ width: '7%', minWidth: '2rem' }} ></Column> */}
                    <Column field="stokKodu" className='text-center text-success' header="Stok Kodu" body={promoBodyTemplate} headerStyle={{ width: '5%', minWidth: '6rem' }}></Column>
                    <Column field="urunAdi" sortable header="Ürün Adı" body={codeBodyTemplate} headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                    <Column field="fiyat" header="Site Fiyati" className='text-center text-success' body={(e) => e.fiyat + " TL"} headerStyle={{ width: '5%', minWidth: '6rem' }} ></Column>
                    <Column field="basefiyat" header="Base Fiyati" className='text-center text-success' body={(e) => e.basefiyat + " TL"} headerStyle={{ width: '6%', minWidth: '6rem' }} ></Column>
                    <Column field="fiyekMaliyetFiyatiat" header="Kar Orani" className='text-center text-success' body={(e) => "%" + e.ekMaliyetFiyati} headerStyle={{ width: '5%', minWidth: '6rem' }} ></Column>
                    <Column field="satisFiyati" className='text-center text-success' header="Satis Fiyati" body={satistutari} headerStyle={{ width: '8%', minWidth: '6rem' }}></Column>
                    {/* <Column field="karMarji" className='text-center ' headerStyle={{ width: '9%', minWidth: '6rem' }} sortable header="Kar Marjı" body={karmarji} ></Column>
                    <Column field="karTutari" className='text-center' headerStyle={{ width: '9%', minWidth: '6rem' }} body={kartutari} header="Kar Tutarı"  ></Column> */}
                    <Column field="komisyonTutari" className='text-center text-success' header="Komisyon Tutarı" headerStyle={{ width: '5%', minWidth: '4rem' }} body={(e) => e.komisyonTutari + "TL"} ></Column>
                    <Column field="kargoFiyati" className='text-center text-success' header="Kargo" body={(e) => e.kargoFiyati + " TL"} headerStyle={{ width: '5%', minWidth: '3rem' }} ></Column>
                    <Column field="toplamMaliyetFiyati" className='text-center text-success' header="Top. Mal." body={(e) => e.toplamMaliyetFiyati + " TL"} headerStyle={{ width: '8%', minWidth: '3rem' }}></Column>
                    <Column field="gonderimDrm" className='text-center text-success' header="Drm" body={gonderimdurumu} headerStyle={{ width: '3%', minWidth: '3rem' }}></Column>
                    <Column header="İşlem Menüsü" bodyStyle={{ textAlign: 'center' }} body={actionBodyTemplate} headerStyle={{ width: '8%', minWidth: '4rem' }}></Column>
                </DataTable>

                <Dialog visible={urunDialog} style={{ width: '800px', height: '350px' }} header="PAZARA ÜRÜN EKLE" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                    <div className="card  mt-1">
                        <div className="p-fluid grid formgrid">
                            <div className="field col-12 md:col-6 ">
                                <span htmlFor="name">Pazar Yeri Seçin</span>
                                <Dropdown
                                    value={urun.pazaryeriID}
                                    options={pazaryeri}
                                    onChange={(e) => onSecimPazarChange(e)}
                                    optionLabel="pazarYeriAdi"
                                    optionValue="pazarYeriID"
                                    placeholder="Pazaryeri Seçin."
                                />
                            </div>
                            <div className="field col-12 md:col-6">
                                <span htmlFor="birim-fiyat">Ürün Seçin</span>
                                <Dropdown
                                    options={urunList}
                                    value={urun.productID}
                                    onChange={(e) => onProductChange(e)}
                                    optionLabel="name"
                                    optionValue="id"
                                    filter showClear placeholder="Lütfen Ürün Seçin"
                                />
                            </div>
                            <div className="field col-12 md:col-6 ">
                                <span htmlFor="name">Kar Oranı</span>
                                <InputNumber value={urun.ekMaliyetFiyati} onValueChange={(e) => onUrunChange(e, 'ekMaliyetFiyati')} prefix="%" />

                            </div>
                            <div className="field col-12 md:col-6">
                                <span htmlFor="birim-fiyat">Desi Bilgisi Seçin</span>
                                <Dropdown
                                    value={urun.desiId}
                                    options={desiList}
                                    onChange={(e) => onDesiChange(e)}
                                    optionLabel={"ad"}
                                    optionValue="id"
                                    placeholder="Desi Seçin."
                                />
                            </div>

                            {urun.fiyatID > 0 ? (
                                <>
                                    <div className="field col-12 md:col-6 mt-1">
                                        <span htmlFor="birim-fiyat">Ürün Fiyatının Gönderimi </span>
                                        <Dropdown
                                            value={urun.gonderimDrm}
                                            className=' mt-1'
                                            options={gonderim}
                                            onChange={(e) => onSecimGonderimChange(e)}
                                            optionLabel="name"
                                            optionValue="code"
                                            placeholder="Pazaryeri Gönderim"
                                        />

                                    </div>
                                </>
                            ) : (
                                <>

                                </>
                            )}
                        </div>
                    </div>
                </Dialog>

                <Dialog visible={desiDialog} style={{ width: '900px', height: '800px' }} header="Pazaryeri Desi Listesi" modal className="p-fluid" onHide={hidedesiDialog}>
                    <div>
                        <DataTable
                            ref={dt}
                            value={desiList}
                            showGridlines
                            dataKey="id"
                            paginator
                            rows={8}
                            className="datatable-responsive"
                            responsiveLayout="scroll"
                            rowsPerPageOptions={[8, 10, 15]}
                            selectiionMode={rowClick ? null : 'checkbox'}
                            selection={desiTablosu}
                            onSelectionChange={selectedDesi}
                            size='small'>
                            <Column header="Seç" selectionMode="single" headerStyle={{ width: '1%', minWidth: '1rem' }}></Column>
                            <Column field="ad" sortable className='text-center text-success' header="Kargo" headerStyle={{ width: '27%', minWidth: '8rem' }} body={(e) => e.ad} ></Column>
                            <Column field="desiNo" className='text-center text-success' headerStyle={{ width: '2%', minWidth: '4rem' }} header="Desi(KG)" body={(e) => e.desiNo} ></Column>
                            <Column field="fiyat" className='text-center text-success' headerStyle={{ width: '10%', minWidth: '4rem' }} header="Fiyat" body={(e) => e.fiyat + " TL"} ></Column>
                            <Column field="sartFiyat" className='text-center text-success' headerStyle={{ width: '10%', minWidth: '4rem' }} sortable header="İndirim Şartı(1)" body={(e) => e.sartFiyat + " TL"}></Column>
                            <Column field="fiyat2" className='text-center text-success' headerStyle={{ width: '10%', minWidth: '4rem' }} sortable header="İndirimli Fiyat(1)" body={(e) => e.fiyat2 + " TL"} ></Column>
                            <Column field="sartFiyat100" className='text-center text-success' headerStyle={{ width: '10%', minWidth: '4rem' }} sortable header="İndirim Şartı(2)" body={(e) => e.sartFiyat100 + " TL"}></Column>
                            <Column field="fiyat100" className='text-center text-success' headerStyle={{ width: '10%', minWidth: '4rem' }} sortable header="İndirim Şartı(2)" body={(e) => e.fiyat100 + " TL"}></Column>

                        </DataTable>
                    </div>
                    <div className="card  mt-1">
                        <div className=" grid formgrid">
                            <div className="field col-12 md:col-8 mt-2 ">
                                <span htmlFor="name">Kargo Firması Seçin</span>
                                <Dropdown
                                    value={desiTablosu.shipperId}
                                    options={kargoList}
                                    className="mt-1"
                                    onChange={(e) => onSecimKargoChange(e)}
                                    optionLabel="companyName"
                                    optionValue="id"
                                    placeholder="Kargo Firması Seçin"
                                />
                            </div>
                            <div className="field col-12 md:col-4 mt-2">
                                <span htmlFor="desiNo">Desi Numarası</span>
                                <InputNumber
                                    name='desiNo'
                                    value={desiTablosu.desiNo}
                                    onValueChange={(e) => onUrunChange(e, 'desiNo')}
                                    className="mt-1"
                                    suffix="" min={0} max={100} />

                            </div>

                            <div className="field col-12 md:col-4  mt-2">
                                <span htmlFor="fiyat">Fiyatı</span>
                                <InputNumber
                                    maxLength={10}
                                    name='fiyat'
                                    value={desiTablosu.fiyat}
                                    onValueChange={(e) => onUrunChange(e, 'fiyat')}
                                    mode="currency" currency="TRY" locale="tr-TR"
                                    className="mt-1"
                                />
                            </div>
                            <div className="field col-12 md:col-4 mt-2">
                                <span htmlFor="birim-fiyat">İndirim Şartı (1)</span>
                                <InputNumber
                                    maxLength={10}
                                    name='sartFiyat'
                                    value={desiTablosu.sartFiyat}
                                    onValueChange={(e) => onUrunChange(e, 'sartFiyat')}
                                    mode="currency" currency="TRY" locale="tr-TR"
                                    className="mt-1"
                                />
                            </div>
                            <div className="field col-12 md:col-4  mt-2">
                                <span htmlFor="fiyat2">İndirimli Fiyatı (1)</span>
                                <InputNumber
                                    maxLength={10}
                                    name='fiyat2'
                                    value={desiTablosu.fiyat2}
                                    onValueChange={(e) => onUrunChange(e, 'fiyat2')}
                                    mode="currency" currency="TRY" locale="tr-TR"
                                    className="mt-1"
                                />

                            </div>
                            <div className="field col-12 md:col-4 mt-2">
                                <span htmlFor="birim-fiyat">İndirim Şartı (2)</span>
                                <InputNumber
                                    maxLength={10}
                                    name='sartFiyat100'
                                    value={desiTablosu.sartFiyat100}
                                    onValueChange={(e) => onUrunChange(e, 'sartFiyat100')}
                                    mode="currency" currency="TRY" locale="tr-TR"
                                    className="mt-1"
                                />
                            </div>
                            <div className="field col-12 md:col-4  mt-2">
                                <span htmlFor="fiyat100">İndirimli Fiyatı (2)</span>
                                <InputNumber
                                    maxLength={10}
                                    name='fiyat100'
                                    value={desiTablosu.fiyat100}
                                    onValueChange={(e) => onUrunChange(e, 'fiyat100')}
                                    mode="currency" currency="TRY" locale="tr-TR"
                                    className="mt-1"
                                />

                            </div>
                            <div className="field col-12 md:col-4  mt-5">
                                <Button label="Kaydet / Güncelle" icon="pi pi-refresh" className="p-button p-button-success" onClick={saveUpdateDesi} />

                            </div>




                        </div>
                    </div>
                </Dialog>



            </div>
        </div>
    );
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};
export default React.memo(PazaryeriFiyatHesaplama, comparisonFn);