import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';

import { InputText } from 'primereact/inputtext';
import axios from 'axios';



const SepetTakip = () => {

    const [sepetekiler, setSepetekiler] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);


    const toast = useRef(null);
    const dt = useRef(null);



    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}Orders/GetSepetekiler`).then((response) => {
            setSepetekiler(response?.data?.data)
           
        })
    }, [])

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">SEPETTEKİ ÜRÜNLER</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
            </span>
        </div>
    );
   

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} position="top-center" />
                    <DataTable
                        ref={dt}
                        showGridlines
                        value={sepetekiler}
                        dataKey="cartID"
                        paginator
                        rows={10}
                        size='small'
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Gösterilen müşteri {first} ile {last} arasında, toplam müşteri sayısı {totalRecords} "
                        globalFilter={globalFilter} emptyMessage="Müşteri bulunamamıştır." header={header} responsiveLayout="scroll">
                        <Column field="cartID" header="Sepet Id" body={(e) => e.cartID} headerStyle={{ width: '3%', minWidth: '5rem' }}></Column>
                        <Column field="CustomerID" header="Müşteri Numara" body={(e)=> e.customerID} headerStyle={{ width: '4%', minWidth: '5rem' }}></Column>
                        <Column field="ekleyenKisi" header="Adı Soyadı" body={(e)=> e.ekleyenKisi} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="urunAdi" header="Ürün Adı" body={(e) => e.urunAdi} headerStyle={{ width: '21%', minWidth: '10rem' }}></Column>
                        <Column field="quantity" header="Adet" body={(e) => e.quantity} headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="total" header="Toplam" body={(e) => e.total} headerStyle={{width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="generalTotal" header="Genel Toplam" body={(e) => e.generalTotal} headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SepetTakip, comparisonFn);